.accordion {
	width: 100%;
	margin-bottom: 1rem;
}

.accordion .card-header {
	border: 1px solid #0000001c;
	border-radius: 5px;
	padding: 0rem;
	vertical-align: middle;
	background-color: #f0f1f675;
	margin: 0;
}

.accordion .card-subheader {
	border: 1px solid #0000001c;
	border-radius: 5px;
	padding: 0rem;
	vertical-align: middle;
	background-color: #fff;
	margin: 0;
}

.child-padding {
	padding: 1.5rem 0 1.5rem 18px !important;
}

.child-container {
	padding-right: 0px;
}

.accordion .card-body {
	padding: 0.5rem 0rem;
}

.accordion .card {
	/* We do this because the css "+" combinator works only on the next sibling
       Thus, <Accordion.Collapse> has to be placed before <Accordion.Toggle> in JSX
       To avoid the odd situation where the accordion's content shows up on top of the selector,
       this CSS reverses the order that shows up on screen with flexbox */
	display: flex;
	flex-direction: column-reverse;
	border: 0px solid whitesmoke;
	cursor: pointer;
	overflow: inherit;
}

.accordion>.card .card-header {
	margin-bottom: 0px;
}

div[class="card-header"] .accordion-arrow {
	transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;

	transform: rotate(0deg);
	-o-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	-webkit-transform: rotate(0deg);
}

/* When Accordion.Toggle is clicked, the class "show" is added to Accordion.Collapse when it opens */
/* https://developer.mozilla.org/en-US/docs/Web/CSS/Attribute_selectors */
div[class~="show"]+div[class="card-header"] .accordion-arrow {
	transform: rotate(90deg);
	-o-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
}

div[class="card-subheader card-header"] .accordion-arrow {
	transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;

	transform: rotate(0deg);
	-o-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	-webkit-transform: rotate(0deg);
}

/* When Accordion.Toggle is clicked, the class "show" is added to Accordion.Collapse when it opens */
/* https://developer.mozilla.org/en-US/docs/Web/CSS/Attribute_selectors */
div[class~="show"]+div[class="card-subheader card-header"] .accordion-arrow {
	transform: rotate(90deg);
	-o-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
}