body {
    font-size: 15px;
    line-height: 1.52;
    color: #717f86;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 400 !important;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}



/* .section-padding {
    padding: 92px 0px 60px;
} */

.section-padding1 {
    padding: 50px 0px 50px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Open Sans, sans-serif;
    font-weight: 700;
    color: #4B4B4C;
}

a {
    color: #345687;
    font-size: 0.875rem;
}

a:hover,
a:focus {
    color: #345687;
}

/***********************************
Navigation bar
************************************/
/* .navbar-default .navbar-brand {
    color: #345687;
} */

/* .navbar-default {
    background-color: #ffffff;
} */

.course-text {
    padding: 84px;
}

/* .navbar-default .navbar-nav>li>a {
    color: #717f86;
    font-size: 14px;
    font-weight: 700;
} */

.navbar-nav>li>a {
    padding: 0px;
    margin: 10px 5px;
    padding: 10px 15px;
}

/* .navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover {
    color: #345687;
} */

.navbar-nav .active a {
    background: none !important;
    color: #345687 !important;
    outline: none;
}

.btn-trial a {
    background-color: #345687;
    border-radius: 25px;
    color: #ffffff !important;
    border: 1px solid #345687;
}

.btn-trial a:hover,
.btn-trial a:focus {
    border: 1px solid #345687;
    background-color: #fff;
    color: #345687 !important;
}

.navbar-default .navbar-brand {
    font-family: 'Dosis', sans-serif !important;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0px;
    height: inherit;
    font-size: 36px;
    margin: 20px 0px;
}

.navbar-default .navbar-brand span {
    font-weight: 100 !important;
}

.navbar {
    margin-bottom: 0px;
}

.padding-zero {
    padding: 0px;
}

.mart20 {
    margin-top: 20px;
}

/***********************************
Banner
************************************/
.banner {
    background: url('../../images/web/bg-image.png') no-repeat center top;
    background-size: cover;
    min-height: 660px;
    position: relative;
}

.bg-color {
    background-color: RGBA(12, 11, 11, 0.52);
    min-height: 660px;
}

.text-dec {
    font-size: 24px;
    padding: 10px 20px;
    margin: 15px 0;
    text-transform: uppercase;
    color: #fff;
}

.text-border {
    border: 5px solid #345687;
    display: inline-block;
    margin-top: 150px;
}

/* .intro-para {
    font-family: "Dosis", sans-serif;
    font-size: 45px;
    color: #fff;
    margin-top: 180px;
} */

.mouse {
    width: 25px;
    height: 45px;
    border: 2px solid #fff;
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -12.5px;
    border-radius: 12px;
}

.mouse::after {
    content: "";
    position: absolute;
    height: 5px;
    width: 5px;
    background-color: #fff;
    border-radius: 100%;
    left: 50%;
    top: 10px;
    margin-left: -2.5px;
    transition: all 0.3s ease-in;

    -webkit-animation-name: rotateplane;
    /* Chrome, Safari, Opera */
    -webkit-animation-duration: 2s;
    /* Chrome, Safari, Opera */
    animation-name: rotateplane;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.modal-content h3 {
    font-size: 1.1em;
    font-weight: 700;

}

.login-box-body {
    padding: 15px 30px;
}

.login-box-msg,
.register-box-msg {
    margin: 0px;
    text-align: center;
    padding: 0px 20px 20px;
}

.modal-sm {
    width: 400px;
}

.padleft-right {
    padding-left: 15px;
    padding-right: 0px;
}

.big-text {
    font-size: 65px;
}

.small-text {
    font-size: 40px;
}

.quote .btn {
    background-color: #345687;
    color: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin-top: 10px;
}

.quote .btn:hover,
.quote .btn:focus {
    background-color: #fff;
    color: #345687;
    border-color: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

/***********************************
Features
************************************/
h2 {
    font-size: 24px;
    padding-bottom: 15px;
}

.bottom-line {
    width: 25px;
    height: 3px;
    background-color: rgb(99, 122, 145);
}

.fea {
    margin-top: 25px;
}

.fea-img {
    width: 12%;
}

.heading {
    width: 88%;
}

.fea i {
    font-size: 30px;
    color: #345687;
    margin-top: 7px;
}

.heading h4 {
    font-size: 17px;
    line-height: 1.25;
    font-weight: 700;
    color: #000;
    margin: 10px 0px;
    text-align: left;
}

.heading p {
    text-align: left;
}

/***********************************
 Organisations
************************************/
#organisations {
    background-color: rgb(247, 247, 247);
}

.orga-stru {
    background-color: #345687;
    width: 109px;
    height: auto;
    padding: 10px 10px 25px 10px;
    text-align: center;
    border-radius: 0px 80px 99px 99px;
    -moz-border-radius: 0px 80px 99px 99px;
    -webkit-border-radius: 0px 80px 99px 99px;
    -o-border-radius: 0px 80px 99px 99px;
}

.orga-stru h3 {
    font-size: 36px;
    color: white;
    font-weight: bold;
}

.orga-stru p {
    font-size: 14px;
    color: white;
    font-weight: normal;
    line-height: 20px;
    padding-top: 15px;
}

.orga-stru i {
    font-size: 150px;
    margin-top: 35px;
    color: rgb(255, 255, 255);
}

h3.det-txt {
    font-size: 40px;
    font-weight: bold;
    text-transform: uppercase;
}

h4.sm-txt {
    color: #345687;
    font-size: 24px;
}

p.det-p {
    padding: 40px 0px;
}

#pricing {
    background: rgb(247, 247, 247);
}

/***********************************
Cta
************************************/
#cta-2 {
    padding: 140px 0;
}

.cta-2-txt {
    font-size: 24px;
    padding-bottom: 15px;
    text-align: center;
    margin: 0 auto;
    line-height: 34px !important;
}

.cta-2-form input[type="email"] {
    border: 0px solid #CCC;
    padding: 13px 40px;
    font-size: 18px;
    color: #2b5d83;
    font-weight: 100;
    min-width: 280px;
    margin-left: -4px;
    margin-bottom: 2px;
    border: 1px solid #345687;
    background-color: rgb(247, 247, 247);
    border-radius: 30px 0px 0px 30px;
}

.cta-2-form-submit-btn {
    font-size: 18px;
    text-transform: capitalize;
    color: #fff;
    background-color: #345687;
    border: 2px solid #fff;
    padding: 14px 30px;
    margin-left: -4px;
    transition: all 0.3s;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    border-width: 1px 1px 1px 0px;
    border-radius: 0px 30px 30px 0px;
}

/***********************************
Work Shop
************************************/
#work-shop {
    background-color: rgb(247, 247, 247);
}

.service-box {
    background: #fff;
    padding: 0px;
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 30px;
}

.icon-box i {
    color: #345687;
    font-size: 65px;
}

.ser-text {
    font-size: 24px;
    margin-bottom: 50px;
}

.icon-box,
.icon-text {
    padding: 20px;
}

/***********************************
Mentor Team
************************************/
.pm-staff-profile-image {
    width: 150px;
    height: 150px;
    background-color: #C6C6C6;
    border-radius: 50%;
    display: inline-block;
}

.pm-staff-profile-details {
    padding: 20px 20px;
}

.pm-staff-profile-name {
    font-size: 24px;
    text-transform: capitalize;
    color: #2b5d83;
}

.pm-staff-profile-title {
    text-transform: capitalize;
    color: #4b4b4b;
    font-weight: 500;
    padding: 12px 0px;
}

.pm-staff-profile-bio {
    text-transform: capitalize;
    color: #4b4b4b;
    font-weight: 100;
}

/***********************************
Testimonial
************************************/
#testimonial {
    background-color: #111;
}

.bg-white {
    background-color: #fff !important;
}

.text-comment {
    background-color: rgb(255, 255, 255);
    padding: 20px;
}

.text-par {
    font-size: 20px;
    padding-bottom: 15px;
}

/***********************************
Course
************************************/
[class*=" imghvr-"] figcaption,
[class^="imghvr-"] figcaption {
    background-color: #345687;
}

[class*=" imghvr-"],
[class^="imghvr-"] {
    background-color: #345687;
    color: #fff;
    border: 1px solid #345687;
}

/***********************************
Price Table
************************************/
.price-table {
    box-shadow: 0px 0px 1px 0px RGBA(0, 0, 0, 0.4);
    position: relative;
}

.price-table .pricing-head {
    background: #fff;
    padding: 40px;
    text-align: center;
}

.price-table .pricing-head h4 {
    font-size: 22px;
    color: #232c3b;
    margin-top: 0px;
    letter-spacing: 1px;
    border-bottom: 1px solid #232c3b;
    padding-bottom: 20px;
}

.pricing-head .amount {
    font-size: 60px;
    font-weight: bold;
}

.pricing-head .curency {
    display: inline-block;
    position: relative;
    top: -30px;
    font-size: 20px;
}

.price-in {
    background: #fff;
    text-align: center;
}

.btn-bg {
    border: 0px;
    border-radius: 0px;
    color: #fff !important;
    padding: 20px 0px;
    font-weight: bold !important;
    font-size: 14px;
    background-color: #345687;
}

/***********************************
Contact Us
************************************/
#contact {
    background-color: rgb(247, 247, 247);
}

.contact form .form {
    width: 100%;
    height: auto;
    padding: 15px 15px 12px;
    font-size: 18px;
    margin-bottom: 18px;
    color: #7f7f7f;
    letter-spacing: 0.5px;
}

#contact .form-control {
    background: #fafafa;
    border: 1px solid rgba(119, 81, 81, 0.34);
    width: 100%;
    margin-bottom: 15px;
    border-radius: 0;

}

.textarea {
    height: 228px;
    max-width: 100%;
    max-height: 229px;
}

.light {
    font-weight: 100;
}

.white {
    color: #fff !important;
}

.validation {
    color: red;
    display: none;
    margin: 0 0 20px;
    font-weight: 400;
    font-size: 13px;
}

#sendmessage {
    color: green;
    border: 1px solid green;
    display: none;
    text-align: center;
    padding: 15px;
    font-weight: 600;
    margin-bottom: 15px;
}

#errormessage {
    color: red;
    display: none;
    border: 1px solid red;
    text-align: center;
    padding: 15px;
    font-weight: 600;
    margin-bottom: 15px;
}

#sendmessage.show,
#errormessage.show,
.show {
    display: block;
}

/***********************************
Footer
************************************/
/* .footer {
    padding: 60px 0 20px;
    background: #111;
    color: #ccc;
}

.footer a {
    color: #fff;
}

.footer h3 {
    font-weight: 300;
    letter-spacing: 0.05em;
    margin: 0 0 40px;
    font-size: 24px;
}

.footer .form-control {
    border-radius: 30px;
    border: none;
    background: rgba(255, 255, 255, .1);
    padding-left: 30px;
    color: #e5e6e7;
    height: 48px;
}

.footer .social-links li a {
    color: #fff;
    font-size: 32px;
    line-height: 50px;
    border-radius: 50%;
    text-align: center;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
}

.footer .social-links li {
    display: inline-block;
    margin: 0 25px 25px;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
}

.footer .mc-trial {
    margin: 20px 0;
}

.footer .social-links {
    list-style: none;
    padding: 0;
    margin: 30px 0 40px;
    padding: 40px 0 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
} */

.btn-submit:hover,
.btn-submit:focus {
    background-color: #345687;
    color: #fff;
}

.btn-submit {
    padding: 14px 12px;
    border-radius: 50px;
    text-transform: uppercase;
    background-color: #345687;
    color: #fff;
}

.btn-submit i {
    padding-left: 8px;
}

.btn-sec {
    margin-top: 20px;
}

.ser-btn {
    padding: 10px 18px;
    border: 0px;
    background-color: #222935;
    color: #fff;
    border-radius: 3px;
    display: inline-block;
    margin-bottom: 20px;
}

.mem-info {
    padding: 45px 10px 0px;
    background-color: #eee;
}

.mem-info h4 {
    font-size: 18px;
    padding: 10px 0px;
}

.mem-info h3 {
    font-size: 24px;
    padding: 15px 0px;
}

.mem-info h5 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.mem-info p {
    margin: 0 0 10px;
}

.agent-social li {
    display: inline-block;
    padding: 0px 10px 0px 0px;
    margin-bottom: 10px;
}

.btn {
    padding: 10px 20px;
}

.light-form-button {
    background: #345687;
    border: 1px solid rgba(119, 81, 81, 0.34);
    width: 24%;
    padding: 10px 10px;
    margin-bottom: 15px;
    color: #fff;
    border: 1px solid rgba(119, 81, 81, 0.34);
    width: 24%;
    margin-bottom: 15px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.btn-green,
.btn-green:hover {
    background-color: #345687;
    color: #fff;
}

.footer .form-control:focus {
    box-shadow: none;
}

.timetable td,
.timetable th {
    border: 1px solid black;
    text-align: center;
}

.container .box {
    display: flex;
    flex-direction: row;
}

/***********************************
************************************
Responsive media queries
************************************
***********************************/
@media (min-width: 769px) and (max-width: 980px) {
    .ser-text {
        font-size: 20px;
        margin-bottom: 50px;
    }

    .service-box,
    .feature-info,
    .orga-stru {
        margin-bottom: 30px;
    }

    .pricing-head .amount {
        font-size: 51px;
    }

    .price-table .pricing-head h4 {
        font-size: 18px;
    }
}

@media (min-width: 451px) and (max-width: 768px) {
    .ser-text {
        font-size: 20px;
        margin-bottom: 30px;
    }

    .service-box,
    .feature-info {
        margin-bottom: 30px;
    }

    .pricing-head .amount {
        font-size: 51px;
    }

    .price-table .pricing-head h4 {
        font-size: 18px;
    }
}

@media (min-width: 20px) and (max-width: 450px) {
    .text-dec {
        font-size: 20px;
    }

    .big-text {
        font-size: 30px;
        padding-left: 10px;
    }

    .small-text {
        font-size: 14px;
        padding-left: 10px;
    }

    /* .intro-para {
        font-size: 30px;
    } */

    .header-section p {
        font-size: 14px;

    }

    .heading {
        margin-bottom: 20px;
    }

    .orga-stru i {
        font-size: 92px;
    }

    .orga-stru {
        padding: 5px 5px 15px 5px;
        width: 75px;
        margin-bottom: 20px;
    }

    .orga-stru h3 {
        font-size: 28px;
    }

    .orga-stru p {
        font-size: 13px;
    }

    h3.det-txt {
        font-size: 28px;
    }

    .cta-2-txt {
        font-size: 20px;
    }

    .service-box,
    .text-comment,
    .price-table {
        margin: 0 15px 30px 15px;
    }

    .contact-form-button {
        width: 40%;
    }

    .footer .social-links li {
        margin: 0 5px 25px;
    }

    .cta-2-form-submit-btn {
        font-size: 10px;
    }

    .cta-2-form input[type="email"] {
        padding: 13px 10px;
        font-size: 10px;
        min-width: 187px;
    }

    .modal-sm {
        width: 300px;
    }

    .login-box-body {
        padding: 15px 20px;
    }

    .navbar-default .navbar-brand {
        margin-left: 10px;
    }

    .navbar-default .navbar-nav>li>a {
        text-align: center;
    }
}

.logo-img {
    width: 270px;
}

h2.title__line {
    color: #345687;
    font-size: 44px;
    line-height: 33px;
    margin-bottom: 23px;
    padding-bottom: 25px;
    position: relative;
    font-family: "Dosis", sans-serif;
    font-weight: 500;
    margin-top: 1.5rem;
}

/* h2.title__line::before {
    background: rgba(0, 0, 0, 0) url(../../images/web/title.png) no-repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 14px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 250px;
} */

figure {
    margin: 0;
    height: 360px !important;
}

.bg-image--12 {
    background-image: url(../../images/web/12.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

#myBtn {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    font-size: 28px;
    border: none;
    outline: none;
    background-color: #ffffff;
    color: #111;
    cursor: pointer;
    padding-bottom: 10px;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    box-shadow: 0 0 15px 0px rgba(50, 50, 50, 0.2);
}

#myBtn:hover {
    background-color: #C7DA3C;
    color: #fff;
}

.margin-btm {
    margin-bottom: 30px;
}

figcaption {
    background: #345687;
    color: #fff;
    padding: 10px 15px;
    height: 360px !important;
}

figcaption h3 {
    color: #fff
}

ul {
    list-style-type: circle;
    margin-top: 20px;
}

.student {
    width: 50px;
    opacity: 0.5;
}

.kids-cnt {
    padding: 10px 0 0 30px;
    text-align: left;
}

.blue-text {
    font-size: 1.4rem;
    color: #345687;
}

.grey-text {
    font-size: 1.6rem;
    color: #717f86;
}

/***********************************************************************************************************/
.navbar-menu-wrapper.d-flex.align-items-stretch {
    width: unset !important;
}

#weblayout .carousel-inner>.item>a>img,
.carousel-inner>.item>img,
.img-responsive,
.thumbnail a>img,
.thumbnail>img {
    display: block !important;
    max-width: 100% !important;
    height: auto !important;
}

@media (min-width: 992px) {
    .col-md-offset-2 {
        margin-left: 16.66666667% !important;
    }

    .col-md-3 {
        width: 25% !important;
    }

    .col-md-1,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9 {
        float: left !important;
    }

    .col-lg-1,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-md-1,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-sm-1,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-xs-1,
    .col-xs-10,
    .col-xs-11,
    .col-xs-12,
    .col-xs-2,
    .col-xs-3,
    .col-xs-4,
    .col-xs-5,
    .col-xs-6,
    .col-xs-7,
    .col-xs-8,
    .col-xs-9 {
        position: relative !important;
        min-height: 1px !important;
        padding-right: 15px !important;
        padding-left: 15px !important;
    }
}

body {
    background-color: #fff !important;
}

div {
    display: block;
}

.container {
    width: 100%;
}

@media (max-width: 768px) {
    .container {
        max-width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1170px;
    }
}

.navbar-brand {
    width: auto !important;
}

/* .navbar-nav a {
    color: #717f86 !important;
    font-size: 14px;
    font-weight: 700;
    margin: 10px 5px;
    padding: 10px 15px;
    font-family: 'Open Sans', sans-serif;
} */

#reportstyle ul li span {
    float: right;
}

#reportImg div img {
    border-radius: 75px;
    border: solid #d8dae2 4px;
    height: 160px;
    padding: 1px;
}

.detail-report-img {
    align-items: center;
    margin-left: 100px;
    margin-top: 51px;
}

.card .card-body {
    /* padding: 1.5rem 6.7px 1.5rem 0.5rem !important; */
    background-color: #fff;
}

.card {
    background-color: transparent;
}

.text-sm a {
    font-size: 12px;
}

.text-sm {
    font-size: 12px;
}

.text-md {
    font-size: 14px;
}

.col-md-4.text-sm {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.col-md-3.text-sm {
    padding-left: 0 !important;
    padding-right: 0 !important;

}

@media only screen and (min-width: 992px) {
    .export {
        text-align: right;
    }

    .addBtn {
        text-align: right;
    }

    .col-md-5.textRight {
        padding-left: 0 !important;
        text-align: right;
    }

    .col-md-4.textRight {
        padding-left: 0 !important;
        text-align: right;
    }

    .col-md-3.textRight {
        padding-left: 0 !important;
        text-align: right;
    }
}

@media only screen and (max-width: 768px) {
    .container {
        margin-top: 50px;
    }

    .import {
        text-align: center;
    }

    input#react-csv-reader-input {
        width: 80px;
    }

    .export {
        text-align: center;
        margin-top: 12px;
    }

    .addBtn {
        text-align: center;
        margin: 15px;
    }
}

.col-6.text-end {
    display: none;
}

.th-height {
    height: 41px;
    width: 70px;
}

.td-height {
    height: 5.8rem;
}

.modal-dialog .MuiToolbar-regular {
    min-height: 1px !important;
}

.rbc-btn-group {
    display: none;
}

.MuiPaper-elevation2 {
    box-shadow: none !important;
}

table {
    border-collapse: collapse;
    width: 13rem;
}

.result-class {
    border-collapse: collapse;
    width: 100%;
}

th,
td {
    text-align: left;
}

.disable:before {
    opacity: 0;
}

text.label {
    font-size: 10px;
}

.xxs-image {
    width: 200px;
}

.textRight a,
.textRight a:focus,
.textRight a:hover,
.export a,
.export a:focus,
.export a:hover {
    color: #345687;
}

.grayColorBox {
    background-color: gray;
    display: inline-block;
    height: 15px;
    width: 15px;
}

.redColorBox {
    background-color: red;
    display: inline-block;
    height: 15px;
    width: 15px;
}

.orangeColorBox {
    background-color: orange;
    display: inline-block;
    height: 15px;
    width: 15px;
}

.yellowColorBox {
    background-color: yellow;
    display: inline-block;
    height: 15px;
    width: 15px;
}

.greenColorBox {
    background-color: green;
    display: inline-block;
    height: 15px;
    width: 15px;
}

.chart-container {
    position: absolute;
}