/* Utilities */

.grid-margin {
  margin-bottom: $card-spacing-y;
}
.grid-margin-sm-0 {
  @media (min-width: 576px) {
    margin-bottom: 0;
  }
}
.grid-margin-md-0 {
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}
.grid-margin-lg-0 {
  @media (min-width: 992px) {
    margin-bottom: 0;
  }
}
.grid-margin-xl-0 {
  @media (min-width: 1200px) {
    margin-bottom: 0;
  }
}
.img-lg {
  width: 92px;
  height: 92px;
}
.img-sm {
  width: 43px;
  height: 43px;
}
.img-xs {
  width: 37px;
  height: 37px;
}
.img-ss {
  width: 26px;
  height: 26px;
}
.stretch-card {
  @include display-flex;
  @include align-items(stretch);
  @include justify-content(stretch);
  >.card{
    width: 100%;
    min-width: 100%;
  }
}

.border-right-sm {
  @media (min-width: 576px) {
    border-right: $border-width solid $border-color;
  }
}
.border-right-md {
  @media (min-width: 768px) {
    border-right: $border-width solid $border-color;
  }
}
.border-right-lg {
  @media (min-width: 992px) {
    border-right: $border-width solid $border-color;
  }
}

.border-left-sm {
  @media (min-width: 576px) {
    border-left: $border-width solid $border-color;
  }
}
.border-left-md {
  @media (min-width: 768px) {
    border-left: $border-width solid $border-color;
  }
}
.border-left-lg {
  @media (min-width: 992px) {
    border-left: $border-width solid $border-color;
  }
}

.text-gray {
  color: #8c8c8c;
}

.text-black {
  color: $black;
}

.text-small {
  font-size: 12px;
}

.flex-grow {
  flex-grow: 1;
}

.font-weight-light {
  font-family: $type1;
  font-weight: 300;
}

.font-weight-bold {
  font-family: $type1;
  font-weight: 700;
}

.font-weight-normal {
  font-family: $type1;
  font-weight: 400;
}

.cursor-pointer {
  cursor: pointer;
}
.image-grouped {
  display: flex;

  .text-avatar,
  img {
    @extend .img-ss;
    border-radius: 100%;
    margin-left: -10px;
    z-index: 0;
    border: 4px solid $card-bg;
    transform: scale(1);
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-property: "box-shadow", "z-index", "transform", "border-width";

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      z-index: 1;
      box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
      transform: scale(1.05);
      border-width: 0;

    }
  }

  .text-avatar {
    @extend .bg-inverse-primary;
    color: theme-color(primary);
    font-size: 11px;
    font-weight: 600;
  }
}
.dot-indicator {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
.img-avatar {
  display: inline-block!important;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  &.img-avatar48 {
    width: 48px;
    height: 48px;
  }
  &.img-avatar-thumb {
    margin: .25rem;
    box-shadow: 0 0 0 0.25rem rgba(255,255,255,.3);
  }
}
.absolute-center {
  position: absolute;
  top: 51%;
  right: 0;
  left: 0;
  margin: auto;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  display: block;
}
.list-timeline {
  margin: 0;
  padding: 5px 0;
  position: relative
}

.list-timeline:before {
  width: 1px;
  background: #ccc;
  position: absolute;
  left: 6px;
  top: 0;
  bottom: 0;
  height: 100%;
  content: ''
}

.list-timeline .list-timeline-item {
  margin: 0;
  padding: 0;
  padding-left: 24px !important;
  position: relative
}

.list-timeline .list-timeline-item:before {
  width: 12px;
  height: 12px;
  background: #fff;
  border: 2px solid #ccc;
  position: absolute;
  left: 0;
  top: 4px;
  content: '';
  border-radius: 100%;
  -webkit-transition: all .3 ease-in-out;
  transition: all .3 ease-in-out
}

.list-timeline .list-timeline-item[data-toggle=collapse] {
  cursor: pointer
}

.list-timeline .list-timeline-item.active:before,
.list-timeline .list-timeline-item.show:before {
  background: #ccc
}

.list-timeline.list-timeline-light .list-timeline-item.active:before,
.list-timeline.list-timeline-light .list-timeline-item.show:before,
.list-timeline.list-timeline-light:before {
  background: #f8f9fa
}

.list-timeline .list-timeline-item.list-timeline-item-marker-middle:before {
  top: 50%;
  margin-top: -6px
}

.list-timeline.list-timeline-light .list-timeline-item:before {
  border-color: #f8f9fa
}

.list-timeline.list-timeline-grey .list-timeline-item.active:before,
.list-timeline.list-timeline-grey .list-timeline-item.show:before,
.list-timeline.list-timeline-grey:before {
  background: #e9ecef
}

.list-timeline.list-timeline-grey .list-timeline-item:before {
  border-color: #e9ecef
}

.list-timeline.list-timeline-grey-dark .list-timeline-item.active:before,
.list-timeline.list-timeline-grey-dark .list-timeline-item.show:before,
.list-timeline.list-timeline-grey-dark:before {
  background: #495057
}

.list-timeline.list-timeline-grey-dark .list-timeline-item:before {
  border-color: #495057
}

.list-timeline.list-timeline-primary .list-timeline-item.active:before,
.list-timeline.list-timeline-primary .list-timeline-item.show:before,
.list-timeline.list-timeline-primary:before {
  background: #55A79A
}

.list-timeline.list-timeline-primary .list-timeline-item:before {
  border-color: #55A79A
}

.list-timeline.list-timeline-primary-dark .list-timeline-item.active:before,
.list-timeline.list-timeline-primary-dark .list-timeline-item.show:before,
.list-timeline.list-timeline-primary-dark:before {
  background: #33635c
}

.list-timeline.list-timeline-primary-dark .list-timeline-item:before {
  border-color: #33635c
}

.list-timeline.list-timeline-primary-faded .list-timeline-item.active:before,
.list-timeline.list-timeline-primary-faded .list-timeline-item.show:before,
.list-timeline.list-timeline-primary-faded:before {
  background: rgba(85, 167, 154, .3)
}

.list-timeline.list-timeline-primary-faded .list-timeline-item:before {
  border-color: rgba(85, 167, 154, .3)
}

.list-timeline.list-timeline-info .list-timeline-item.active:before,
.list-timeline.list-timeline-info .list-timeline-item.show:before,
.list-timeline.list-timeline-info:before {
  background: #17a2b8
}

.list-timeline.list-timeline-info .list-timeline-item:before {
  border-color: #17a2b8
}

.list-timeline.list-timeline-success .list-timeline-item.active:before,
.list-timeline.list-timeline-success .list-timeline-item.show:before,
.list-timeline.list-timeline-success:before {
  background: #28a745
}

.list-timeline.list-timeline-success .list-timeline-item:before {
  border-color: #28a745
}

.list-timeline.list-timeline-warning .list-timeline-item.active:before,
.list-timeline.list-timeline-warning .list-timeline-item.show:before,
.list-timeline.list-timeline-warning:before {
  background: #ffc107
}

.list-timeline.list-timeline-warning .list-timeline-item:before {
  border-color: #ffc107
}

.list-timeline.list-timeline-danger .list-timeline-item.active:before,
.list-timeline.list-timeline-danger .list-timeline-item.show:before,
.list-timeline.list-timeline-danger:before {
  background: #dc3545
}

.list-timeline.list-timeline-danger .list-timeline-item:before {
  border-color: #dc3545
}

.list-timeline.list-timeline-dark .list-timeline-item.active:before,
.list-timeline.list-timeline-dark .list-timeline-item.show:before,
.list-timeline.list-timeline-dark:before {
  background: #343a40
}

.list-timeline.list-timeline-dark .list-timeline-item:before {
  border-color: #343a40
}

.list-timeline.list-timeline-secondary .list-timeline-item.active:before,
.list-timeline.list-timeline-secondary .list-timeline-item.show:before,
.list-timeline.list-timeline-secondary:before {
  background: #6c757d
}

.list-timeline.list-timeline-secondary .list-timeline-item:before {
  border-color: #6c757d
}

.list-timeline.list-timeline-black .list-timeline-item.active:before,
.list-timeline.list-timeline-black .list-timeline-item.show:before,
.list-timeline.list-timeline-black:before {
  background: #000
}

.list-timeline.list-timeline-black .list-timeline-item:before {
  border-color: #000
}

.list-timeline.list-timeline-white .list-timeline-item.active:before,
.list-timeline.list-timeline-white .list-timeline-item.show:before,
.list-timeline.list-timeline-white:before {
  background: #fff
}

.list-timeline.list-timeline-white .list-timeline-item:before {
  border-color: #fff
}

.list-timeline.list-timeline-green .list-timeline-item.active:before,
.list-timeline.list-timeline-green .list-timeline-item.show:before,
.list-timeline.list-timeline-green:before {
  background: #55A79A
}

.list-timeline.list-timeline-green .list-timeline-item:before {
  border-color: #55A79A
}

.list-timeline.list-timeline-red .list-timeline-item.active:before,
.list-timeline.list-timeline-red .list-timeline-item.show:before,
.list-timeline.list-timeline-red:before {
  background: #BE3E1D
}

.list-timeline.list-timeline-red .list-timeline-item:before {
  border-color: #BE3E1D
}

.list-timeline.list-timeline-blue .list-timeline-item.active:before,
.list-timeline.list-timeline-blue .list-timeline-item.show:before,
.list-timeline.list-timeline-blue:before {
  background: #00ADBB
}

.list-timeline.list-timeline-blue .list-timeline-item:before {
  border-color: #00ADBB
}

.list-timeline.list-timeline-purple .list-timeline-item.active:before,
.list-timeline.list-timeline-purple .list-timeline-item.show:before,
.list-timeline.list-timeline-purple:before {
  background: #b771b0
}

.list-timeline.list-timeline-purple .list-timeline-item:before {
  border-color: #b771b0
}

.list-timeline.list-timeline-pink .list-timeline-item.active:before,
.list-timeline.list-timeline-pink .list-timeline-item.show:before,
.list-timeline.list-timeline-pink:before {
  background: #CC164D
}

.list-timeline.list-timeline-pink .list-timeline-item:before {
  border-color: #CC164D
}

.list-timeline.list-timeline-orange .list-timeline-item.active:before,
.list-timeline.list-timeline-orange .list-timeline-item.show:before,
.list-timeline.list-timeline-orange:before {
  background: #e67e22
}

.list-timeline.list-timeline-orange .list-timeline-item:before {
  border-color: #e67e22
}

.list-timeline.list-timeline-lime .list-timeline-item.active:before,
.list-timeline.list-timeline-lime .list-timeline-item.show:before,
.list-timeline.list-timeline-lime:before {
  background: #b1dc44
}

.list-timeline.list-timeline-lime .list-timeline-item:before {
  border-color: #b1dc44
}

.list-timeline.list-timeline-blue-dark .list-timeline-item.active:before,
.list-timeline.list-timeline-blue-dark .list-timeline-item.show:before,
.list-timeline.list-timeline-blue-dark:before {
  background: #34495e
}

.list-timeline.list-timeline-blue-dark .list-timeline-item:before {
  border-color: #34495e
}

.list-timeline.list-timeline-red-dark .list-timeline-item.active:before,
.list-timeline.list-timeline-red-dark .list-timeline-item.show:before,
.list-timeline.list-timeline-red-dark:before {
  background: #a10f2b
}

.list-timeline.list-timeline-red-dark .list-timeline-item:before {
  border-color: #a10f2b
}

.list-timeline.list-timeline-brown .list-timeline-item.active:before,
.list-timeline.list-timeline-brown .list-timeline-item.show:before,
.list-timeline.list-timeline-brown:before {
  background: #91633c
}

.list-timeline.list-timeline-brown .list-timeline-item:before {
  border-color: #91633c
}

.list-timeline.list-timeline-cyan-dark .list-timeline-item.active:before,
.list-timeline.list-timeline-cyan-dark .list-timeline-item.show:before,
.list-timeline.list-timeline-cyan-dark:before {
  background: #008b8b
}

.list-timeline.list-timeline-cyan-dark .list-timeline-item:before {
  border-color: #008b8b
}

.list-timeline.list-timeline-yellow .list-timeline-item.active:before,
.list-timeline.list-timeline-yellow .list-timeline-item.show:before,
.list-timeline.list-timeline-yellow:before {
  background: #D4AC0D
}

.list-timeline.list-timeline-yellow .list-timeline-item:before {
  border-color: #D4AC0D
}

.list-timeline.list-timeline-slate .list-timeline-item.active:before,
.list-timeline.list-timeline-slate .list-timeline-item.show:before,
.list-timeline.list-timeline-slate:before {
  background: #5D6D7E
}

.list-timeline.list-timeline-slate .list-timeline-item:before {
  border-color: #5D6D7E
}

.list-timeline.list-timeline-olive .list-timeline-item.active:before,
.list-timeline.list-timeline-olive .list-timeline-item.show:before,
.list-timeline.list-timeline-olive:before {
  background: olive
}

.list-timeline.list-timeline-olive .list-timeline-item:before {
  border-color: olive
}

.list-timeline.list-timeline-teal .list-timeline-item.active:before,
.list-timeline.list-timeline-teal .list-timeline-item.show:before,
.list-timeline.list-timeline-teal:before {
  background: teal
}

.list-timeline.list-timeline-teal .list-timeline-item:before {
  border-color: teal
}

.list-timeline.list-timeline-green-bright .list-timeline-item.active:before,
.list-timeline.list-timeline-green-bright .list-timeline-item.show:before,
.list-timeline.list-timeline-green-bright:before {
  background: #2ECC71
}

.list-timeline.list-timeline-green-bright .list-timeline-item:before {
  border-color: #2ECC71
}