body {
  font-size: 15px;
  line-height: 1.52;
  color: #000;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}


.h1,
h1 {
  font-size: 36px;
}

.h5,
h5 {
  font-size: 14px;
}

.h4,
h4 {
  font-size: 18px;
}

h6,
.h6 {
  font-size: 13px;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.section-padding {
  padding: 37px 0px 60px;
}

.section-padding1 {
  padding: 50px 0px 50px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  color: #4b4b4c;
}

a {
  color: #23abe2;
}

a:hover,
a:focus {
  color: #23abe2;
}

/***********************************
Navigation bar
************************************/
.navbar-default .navbar-brand {
  color: #23abe2;
}

.navbar-default {
  background-color: #ffffff;
}

.course-text {
  padding: 84px;
}

.navbar-default .navbar-nav>li>a {
  color: #000;
  /* font-size: 1.5rem;
  font-weight: 700; */
  font-size: 14px;
  font-weight: 700;
  margin: 10px 5px;
  padding: 10px 10px;
  font-family: 'Open Sans', sans-serif;
}

.navbar-nav>li>a {
  padding: 0px;
  margin: 10px 5px;
  padding: 5px 15px;
}

.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover {
  color: #000;
}

.navbar-nav .active a {
  background: none !important;
  color: #000 !important;
  outline: none;
  border-bottom: 1px solid #000;
}

.btn-trial a {
  background-color: #000;
  border-radius: 0;
  color: #ffffff !important;
  border: 1px solid #000;
}

.btn-trial a:hover,
.btn-trial a:focus {
  border: 1px solid #000;
  background-color: #fff;
  color: #000 !important;
}

.navbar-default .navbar-brand {
  font-family: "Dosis", sans-serif !important;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0px;
  height: inherit;
  font-size: 36px;
  margin: 20px 0px;
}

.navbar-default .navbar-brand span {
  font-weight: 100 !important;
}

.navbar {
  margin-bottom: 0px;
}

.padding-zero {
  padding: 0px;
}

.mart20 {
  margin-top: 20px;
}

/***********************************
Banner
************************************/
.banner {
  /* background: url("../img/bg-image.png") no-repeat center top; */
  background: url('../../images/web/bg-image.png') no-repeat center top;
  background-size: cover;
  min-height: 660px;
  position: relative;
}

.bg-color {
  background-color: RGBA(12, 11, 11, 0.52);
  min-height: 660px;
}

.text-dec {
  font-size: 24px;
  padding: 10px 20px;
  margin: 15px 0;
  text-transform: uppercase;
  color: #fff;
}

.text-border {
  border: 5px solid #23abe2;
  display: inline-block;
  margin-top: 150px;
}

.intro-para {
  font-size: 40px;
  color: #fff;
  /* margin-top: 86px; */
  font-weight: 900;
  background: #000;
  padding: 85px 0;
  text-align: center;
  text-transform: uppercase;
  font-family: sans-serif !important;
}

/* .quote .btn {
  background-color: ;
} */

.border-white {
  border: 1px solid #fff;
  padding: 10px 15px;
  display: inline-flex;
  font-size: 1.8rem;
  font-weight: normal;
  text-transform: uppercase;
  margin: 3rem 1rem 1rem 1rem;
}

.mouse {
  width: 25px;
  height: 45px;
  border: 2px solid #fff;
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -12.5px;
  border-radius: 12px;
}

@-webkit-keyframes rotateplane {
  0% {
    top: 10px;
  }

  25% {
    top: 14px;
  }

  50% {
    top: 19px;
  }

  75% {
    top: 24px;
  }

  100% {
    top: 28px;
  }
}

@-moz-keyframes rotateplane {
  0% {
    top: 10px;
  }

  25% {
    top: 14px;
  }

  50% {
    top: 19px;
  }

  75% {
    top: 24px;
  }

  100% {
    top: 28px;
  }
}

@-o-keyframes rotateplane {
  0% {
    top: 10px;
  }

  25% {
    top: 14px;
  }

  50% {
    top: 19px;
  }

  75% {
    top: 24px;
  }

  100% {
    top: 28px;
  }
}

.mouse::after {
  content: "";
  position: absolute;
  height: 5px;
  width: 5px;
  background-color: #fff;
  border-radius: 100%;
  left: 50%;
  top: 10px;
  margin-left: -2.5px;
  transition: all 0.3s ease-in;

  -webkit-animation-name: rotateplane;
  /* Chrome, Safari, Opera */
  -webkit-animation-duration: 2s;
  /* Chrome, Safari, Opera */
  animation-name: rotateplane;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.modal-content h3 {
  font-size: 1.1em;
  font-weight: 700;
}

.login-box-body {
  padding: 15px 30px;
}

.login-box-msg,
.register-box-msg {
  margin: 0px;
  text-align: center;
  padding: 0px 20px 20px;
}

.modal-sm {
  width: 400px;
}

.padleft-right {
  padding-left: 15px;
  padding-right: 0px;
}

.big-text {
  font-size: 45px;
}

.small-text {
  font-size: 30px;
}

.quote .btn {
  background-color: #23abe2;
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-top: 10px;
}

.quote .btn:hover,
.quote .btn:focus {
  background-color: #fff;
  color: #23abe2;
  border-color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/***********************************
Features
************************************/
h2 {
  font-size: 24px;
  padding-bottom: 15px;
}

.bottom-line {
  width: 25px;
  height: 3px;
  background-color: rgb(99, 122, 145);
}

.fea {
  margin-top: 25px;
}

.fea-img {
  width: 12%;
}

.heading {
  width: 88%;
}

.fea i {
  font-size: 30px;
  color: #23abe2;
  margin-top: 7px;
}

.heading h4 {
  font-size: 17px;
  line-height: 1.25;
  font-weight: 700;
  color: #000;
  margin: 10px 0px;
  text-align: left;
}

.heading p {
  text-align: left;
}

/***********************************
 Organisations
************************************/
#organisations {
  background-color: rgb(247, 247, 247);
}

.orga-stru {
  background-color: #23abe2;
  width: 109px;
  height: auto;
  padding: 10px 10px 25px 10px;
  text-align: center;
  border-radius: 0px 80px 99px 99px;
  -moz-border-radius: 0px 80px 99px 99px;
  -webkit-border-radius: 0px 80px 99px 99px;
  -o-border-radius: 0px 80px 99px 99px;
}

.orga-stru h3 {
  font-size: 36px;
  color: white;
  font-weight: bold;
}

.orga-stru p {
  font-size: 14px;
  color: white;
  font-weight: normal;
  line-height: 20px;
  padding-top: 15px;
}

.orga-stru i {
  font-size: 150px;
  margin-top: 35px;
  color: rgb(255, 255, 255);
}

h3.det-txt {
  font-size: 40px;
  font-weight: bold;
  text-transform: uppercase;
}

h4.sm-txt {
  color: #23abe2;
  font-size: 24px;
}

p.det-p {
  padding: 40px 0px;
}

#pricing {
  background: rgb(247, 247, 247);
}

/***********************************
Cta
************************************/
#cta-2 {
  padding: 140px 0;
}

.cta-2-txt {
  font-size: 24px;
  padding-bottom: 15px;
  text-align: center;
  margin: 0 auto;
  line-height: 34px !important;
}

.cta-2-form input[type="email"] {
  border: 0px solid #ccc;
  padding: 13px 40px;
  font-size: 18px;
  color: #2b5d83;
  font-weight: 100;
  min-width: 280px;
  margin-left: -4px;
  margin-bottom: 2px;
  border: 1px solid #23abe2;
  background-color: rgb(247, 247, 247);
  border-radius: 30px 0px 0px 30px;
}

.cta-2-form-submit-btn {
  font-size: 18px;
  text-transform: capitalize;
  color: #fff;
  background-color: #23abe2;
  border: 2px solid #fff;
  padding: 14px 30px;
  margin-left: -4px;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  border-width: 1px 1px 1px 0px;
  border-radius: 0px 30px 30px 0px;
}

/***********************************
Work Shop
************************************/
#work-shop {
  background-color: rgb(247, 247, 247);
}

.service-box {
  background: #f7f7f7;
  padding: 0px;
  border-radius: 0;
  margin-bottom: 30px;
  color: #000;
  min-height: 252px;
}

.icon-box i {
  color: #23abe2;
  font-size: 65px;
}

.ser-text {
  font-size: 18px;
  margin-bottom: 30px;
  line-height: 1.5;
  color: #000;
  font-weight: bold;
}

.icon-box,
.icon-text {
  padding: 20px;
}

.icon-box,
.icon-text1 {
  padding: 20px;
}

/***********************************
Mentor Team
************************************/
.pm-staff-profile-image {
  width: 150px;
  height: 150px;
  background-color: #c6c6c6;
  border-radius: 50%;
  display: inline-block;
}

.pm-staff-profile-details {
  padding: 20px 20px;
}

.pm-staff-profile-name {
  font-size: 24px;
  text-transform: capitalize;
  color: #2b5d83;
}

.pm-staff-profile-title {
  text-transform: capitalize;
  color: #4b4b4b;
  font-weight: 500;
  padding: 12px 0px;
}

.pm-staff-profile-bio {
  text-transform: capitalize;
  color: #4b4b4b;
  font-weight: 100;
}

/***********************************
Testimonial
************************************/
#testimonial {
  background-color: #111;
}

.bg-white {
  background-color: #fff !important;
}

.text-comment {
  background-color: rgb(255, 255, 255);
  padding: 20px;
}

.text-par {
  font-size: 20px;
  padding-bottom: 15px;
}

/***********************************
Course
************************************/
[class*=" imghvr-"] figcaption,
[class^="imghvr-"] figcaption {
  background-color: #23abe2;
}

[class*=" imghvr-"],
[class^="imghvr-"] {
  background-color: #23abe2;
  color: #fff;
  border: 1px solid #23abe2;
}

/***********************************
Price Table
************************************/
.price-table {
  box-shadow: 0px 0px 1px 0px RGBA(0, 0, 0, 0.4);
  position: relative;
}

.price-table .pricing-head {
  background: #fff;
  padding: 40px;
  text-align: center;
}

.price-table .pricing-head h4 {
  font-size: 22px;
  color: #232c3b;
  margin-top: 0px;
  letter-spacing: 1px;
  border-bottom: 1px solid #232c3b;
  padding-bottom: 20px;
}

.pricing-head .amount {
  font-size: 60px;
  font-weight: bold;
}

.pricing-head .curency {
  display: inline-block;
  position: relative;
  top: -30px;
  font-size: 20px;
}

.price-in {
  background: #fff;
  text-align: center;
}

.btn-bg {
  border: 0px;
  border-radius: 0px;
  color: #fff !important;
  padding: 20px 0px;
  font-weight: bold !important;
  font-size: 14px;
  background-color: #23abe2;
}

/***********************************
Contact Us
************************************/
#blogs {
  background-color: #f7f7f7;
}

.contact form .form {
  width: 100%;
  height: auto;
  padding: 15px 15px 12px;
  font-size: 18px;
  margin-bottom: 18px;
  color: #7f7f7f;
  letter-spacing: 0.5px;
}

#contact1 .form-control {
  background: #fff;
  border: 1px solid #000;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 0;
}

.textarea {
  height: 228px;
  max-width: 100%;
  max-height: 229px;
}

.light {
  font-weight: 100;
}

.white {
  color: #fff !important;
}

.validation {
  color: red;
  display: none;
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 13px;
}

#sendmessage {
  color: green;
  border: 1px solid green;
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#errormessage {
  color: red;
  display: none;
  border: 1px solid red;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#sendmessage.show,
#errormessage.show,
.show {
  display: block;
}

/***********************************
Footer
************************************/
.footer {
  padding: 37px 0 20px;
  background: #111;
  color: #ccc;
}

.footer a {
  color: #fff;
}

.footer h3 {
  font-weight: 300;
  letter-spacing: 0.05em;
  margin: 0 0 40px;
  font-size: 24px;
  color: #f7f7f7;
}

.footer .form-control {
  border-radius: 30px;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  padding-left: 30px;
  color: #e5e6e7;
  margin-bottom: 0;
  height: 48px;
}

.footer .social-links li a {
  color: #fff;
  font-size: 32px;
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.footer .social-links li {
  display: inline-block;
  margin: 0 25px 25px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.footer .mc-trial {
  margin: 20px 0;
}

.footer .social-links {
  list-style: none;
  padding: 0;
  margin: 30px 0 40px;
  padding: 40px 0 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.btn-submit:hover,
.btn-submit:focus {
  background-color: #23abe2;
  color: #fff;
}

.btn-submit {
  padding: 14px 12px;
  border-radius: 50px;
  text-transform: uppercase;
  background-color: #23abe2;
  color: #fff;
}

.btn-submit i {
  padding-left: 8px;
}

.btn-sec {
  margin-top: 20px;
}

.ser-btn {
  padding: 10px 18px;
  border: 0px;
  background-color: #222935;
  color: #fff;
  border-radius: 3px;
  display: inline-block;
  margin-bottom: 20px;
}

.mem-info {
  padding: 45px 10px 0px;
  background-color: #eee;
}

.mem-info h4 {
  font-size: 18px;
  padding: 10px 0px;
}

.mem-info h3 {
  font-size: 24px;
  padding: 15px 0px;
}

.mem-info h5 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mem-info p {
  margin: 0 0 10px;
}

.agent-social li {
  display: inline-block;
  padding: 0px 10px 0px 0px;
  margin-bottom: 10px;
}

.btn {
  padding: 10px 20px;
}

.light-form-button {
  background: #000;
  border: 1px solid #000;
  width: 24%;
  padding: 10px 10px;
  margin-bottom: 15px;
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.btn-green,
.btn-green:hover {
  background-color: #23abe2;
  color: #fff;
}

.footer .form-control:focus {
  box-shadow: none;
}

/***********************************
************************************
Responsive media queries
************************************
***********************************/
@media (min-width: 769px) and (max-width: 980px) {
  .ser-text {
    font-size: 20px;
    margin-bottom: 50px;
  }

  .service-box,
  .feature-info,
  .orga-stru {
    margin-bottom: 30px;
  }

  .pricing-head .amount {
    font-size: 51px;
  }

  .price-table .pricing-head h4 {
    font-size: 18px;
  }
}

@media (min-width: 451px) and (max-width: 768px) {
  .ser-text {
    font-size: 20px;
    margin-bottom: 30px;
  }

  .service-box,
  .feature-info {
    margin-bottom: 30px;
  }

  .pricing-head .amount {
    font-size: 51px;
  }

  .price-table .pricing-head h4 {
    font-size: 18px;
  }

  h2.title__line1 {
    font-size: 15px;
  }
}

@media (min-width: 20px) and (max-width: 450px) {
  .text-dec {
    font-size: 20px;
  }

  .big-text {
    font-size: 30px;
    padding-left: 10px;
  }

  .small-text {
    font-size: 14px;
    padding-left: 10px;
  }

  .intro-para {
    font-size: 30px;
  }

  .header-section p {
    font-size: 14px;
  }

  .heading {
    margin-bottom: 20px;
  }

  .orga-stru i {
    font-size: 92px;
  }

  .orga-stru {
    padding: 5px 5px 15px 5px;
    width: 75px;
    margin-bottom: 20px;
  }

  .orga-stru h3 {
    font-size: 28px;
  }

  .orga-stru p {
    font-size: 13px;
  }

  h3.det-txt {
    font-size: 28px;
  }

  .cta-2-txt {
    font-size: 20px;
  }

  .service-box,
  .text-comment,
  .price-table {
    margin: 0 15px 30px 15px;
  }

  .contact-form-button {
    width: 40%;
  }

  .footer .social-links li {
    margin: 0 5px 25px;
  }

  .cta-2-form-submit-btn {
    font-size: 10px;
  }

  .cta-2-form input[type="email"] {
    padding: 13px 10px;
    font-size: 10px;
    min-width: 187px;
  }

  .modal-sm {
    width: 300px;
  }

  .login-box-body {
    padding: 15px 20px;
  }

  .navbar-default .navbar-brand {
    margin-left: 10px;
  }

  .navbar-default .navbar-nav>li>a {
    text-align: center;
  }
}

.logo-img {
  width: 250px;
}

h2.title__line {
  color: #000;
  font-size: 30px;
  line-height: 20px;
  margin-bottom: 23px;
  padding-bottom: 25px;
  position: relative;
  font-weight: bold;
  text-transform: uppercase;
}

h2.title__line::before {
  bottom: 0;
  content: "";
  height: 14px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 95px;
  border-bottom: 3px solid #000;
}

figure {
  margin: 0;
  height: 360px !important;
}

.bg-image--12 {
  /* background-image: url(../img/12.jpg); */
  background-image: url(../../images/web/12.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

#myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 28px;
  border: none;
  outline: none;
  background-color: #ffffff;
  color: #111;
  cursor: pointer;
  padding-bottom: 10px;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  box-shadow: 0 0 15px 0px rgba(50, 50, 50, 0.2);
}

#myBtn:hover {
  background-color: #000;
  color: #fff;
}

.margin-btm {
  margin-bottom: 30px;
}

figcaption {
  background: #23abe2;
  color: #fff;
  padding: 10px 15px;
  height: 360px !important;
}

figcaption h3 {
  color: #fff;
}

ul {
  list-style-type: circle;
  margin-top: 20px;
}

ul li {
  margin-bottom: 15px;
}

.student {
  width: 50px;
  opacity: 0.5;
}

.kids-cnt {
  padding: 10px 0 0 30px;
  text-align: left;
}

.blue-text {
  font-size: 2.2rem;
  color: #23abe2;
}

.grey-text {
  font-size: 2.9rem;
  color: #717f86;
}

.navbar-default {
  background-color: #fff;
  border-color: transparent !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11);
}

.tagline {
  font-size: 18px !important;
  color: #000 !important;
  font-style: italic !important;
}

.blog_card {
  margin: 50px 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: none;
  box-shadow: 0 12px 15px rgba(0, 0, 0, 0.1), 0 17px 50px rgba(0, 0, 0, 0.1);
  border-radius: 0.375rem;
}

.blog_card_content {
  padding: 20px;
}

.blog_card_content h3 {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  margin-bottom: 20px;
  letter-spacing: -0.5;
}

.blog_card_content p {
  font-size: 16px;
  color: #000;
  line-height: 30px;
}

.blog_card_content a {
  text-decoration: none;
}

.border-white a {
  color: #fff;
}

.box {
  height: 200px;
  position: relative;
}

.box-back {
  background-color: #000;
}

.box-white {
  background-color: #f0f0f0;
}

.second-cont {
  margin-top: 30px;
}

.box-back h1 {
  color: white;
  font-size: 54px;
  font-weight: 1000;
}

@media (max-width: 990px) {
  .abox {
    margin-top: 26px;
  }
}

@media (max-width: 767px) {
  .abox {
    margin-top: 0px;
  }

  .box-back {
    margin-top: 26px;
  }

  .box-white {
    margin-top: 26px;
  }

  .second-cont {
    margin-top: unset;
  }

  .solutions {
    margin-top: 25px;
  }
}

h2.title__line1 {
  color: #000;
  font-size: 30px;
  margin-bottom: 23px;
  padding-bottom: 25px;
  position: relative;
  font-weight: bold;
  text-transform: uppercase;
}

h2.title__line1 ::before {
  bottom: 0;
  content: "";
  height: 14px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 95px;
  border-bottom: 3px solid #000;
}

.impact-text {
  text-align: center;
}

.new_line {
  color: white;
  font-size: 30px;
  margin-bottom: 20px;
  position: relative;
  font-weight: bold;
  text-transform: uppercase;
  background: black;
  padding-top: 15px;
}

.solutions {
  height: 430px;
  position: relative;
  border: 1px solid black;
}

.solutions h2 {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: black;
  text-transform: uppercase;
}

.solutions h1 {
  text-align: center;
  font-size: 27px;
  font-weight: bold;
  color: #1ea7df;
  margin-top: -25px;
  text-transform: uppercase;
}

.solutions p {
  margin-top: 20px;
  padding: 0 20px;
  font-weight: 500;
  font-size: 16px;
}

.edvantum-section {
  padding: 74px 0px 60px;
}

.circle {
  width: 50px;
  height: 50px;
  border: 1px solid black;
  border-radius: 50%;
  margin: auto;
}

.horizontal-circle {
  display: flex;
}

.horizontal-circle2 {
  display: flex;
  margin-top: 100px;
}

.Reduces-school {
  margin-top: 180px;
}

.Maximizing {
  display: flex;
  padding: 11px;
}

.Maximizing h4 {
  font-weight: 900;
  color: black;
}

.Maximizing h5 {
  font-size: 17px;
  color: black;
}

.Functions .col-md-4 {
  border: 0.3px solid black;
  height: 250px;
  background-color: #dddada;
}

.Functions h1 {
  color: black;
  padding: 42px 14px 0px 23px;
}

.Functions img {
  height: 100%;
  width: 110%;
  margin-left: -15px;
}

hr {
  border-top: 1px solid black;
  margin-bottom: 5px;
}

.collection h4 {
  margin-top: 30px !important;
  margin-bottom: 40px;
  line-height: 1.2;
}

.collection img {
  margin-top: 44px;
}

.Digitally h4 {
  margin-bottom: 3px !important;
  margin-top: 25px !important;
}

@media (min-width: 990px) and (max-width: 1198px) {
  .solutions {
    height: 461px;
  }

  .solutions p {
    margin-top: 16px;
  }
}

.imageCover {
  padding: 20px;
  margin-top: 30px;
}

.seTitle {
  padding: 50px 0px 0px 0px;
}

.post-heading h2 {
  margin-top: 50px;
  padding-bottom: 0px !important;
}

@media (max-width: 991px) {
  .imageCover {
    padding: 20px;
    margin-top: 78px;
    height: 232px;
  }

  .Reduces-school {
    margin-top: 125px;
  }

  .Maximizing h5 {
    font-size: 14px;
  }

  .post-heading h2 {
    font-size: 21px !important;
  }
}

@media (max-width: 726px) {
  .Reduces-school {
    margin-top: 80px;
  }
}

@media (max-width: 726px) {
  .Reduces-school {
    margin-top: 85px;
  }

  .Maximizing h5 {
    font-size: 10px;
  }

  .Maximizing h4 {
    font-size: 12px;
  }

  .imageCover {
    margin-top: 20px;
  }
}

@media (max-width: 526px) {
  .Reduces-school {
    margin-top: 40px;
  }

  .imageCover {
    margin-top: 50px;
    height: 150px;
  }

  .post-heading h2 {
    font-size: 15px !important;
    margin-top: 9px !important;
  }
}

.setImg {
  text-align: center;
  margin-top: -32px;
}

.our .solutions {
  margin-top: 40px;
}

.why-edvImg {
  padding: 0px 34px 0px 34px;
  margin-top: 109px !important;
}

.section-ed {
  display: flex;
}

.tansfer {
  width: 700px;
}

.visibilities h4 {
  margin-bottom: 53px;
}

@media (max-width: 991px) {
  .why-edvImg {
    height: 256px;
    margin-top: 115px !important;
  }

  .collection img {
    margin-top: 25px;
  }

  .collection h4 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 14px;
  }

  .tansfer {
    width: 602px;
  }

  .academic {
    margin-top: 12px;
  }

  .acedmImg {
    margin-top: 13px !important;
  }
}

@media (max-width: 726px) {
  .why-edvImg {
    height: 200px;
    margin-top: 100px !important;
  }

  .collection img {
    margin-top: 20px;
  }

  .collection h4 {
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 10px;
  }

  .tansfer {
    width: 500px;
  }

  .academic {
    margin-top: 9px;
  }

  .acedmImg {
    margin-top: 10px !important;
  }

  .customfont1 {
    font-size: 12px;
  }
}

.imgact1 {
  max-width: 200px;
  width: auto;
}

@media (max-width: 526px) {
  .why-edvImg {
    height: 126px;
    margin-top: 90px !important;
  }

  .collection img {
    margin-top: 10px;
  }

  .collection h4 {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 5px;
  }

  .tansfer {
    width: 200px;
  }

  .academic {
    margin-top: 5px;
  }

  .acedmImg {
    margin-top: 5px !important;
  }

  .Digitally h4 {
    margin-top: 16px;
  }
}

/* Rohit CSS */
.box_blank {
  height: 130px;
  position: relative;
}

.box_long {
  height: 580px;
  width: 400px;
  position: relative;
  box-shadow: 0 12px 15px rgba(0, 0, 0, 0.1), 0 17px 50px rgba(0, 0, 0, 0.1);
}

.box_auto {
  padding: 10px;
  height: auto;
  margin-bottom: 10px;
  position: relative;
  box-shadow: 0 12px 15px rgba(0, 0, 0, 0.1), 0 17px 50px rgba(0, 0, 0, 0.1);
}

.bordered_content {
  padding: 20px;
  border: 3px solid #1a2c77;
  margin: 10px;
  font-size: 12px;
}

.bordered_name_box {
  padding: 20px;
  border: 3px solid #1a2c77;
  background: #dddada;
  width: fit-content;
  max-height: 20px;
  position: relative;
  text-align: center;
  margin-bottom: -25px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  color: #1a2c77;
  font-weight: bold;
  font-size: 1.5rem;
}

.Delivering {
  padding: unset;
}

@media (max-width: 991px) {
  .Functions img {
    width: 104.6%;
  }
}

p .customfont1 {
  font-size: 8px;
}

.imgact1 {
  max-width: 200px;
  width: 25vw;
}

.imgact2 {
  max-width: 200px;
  width: 200px;
}

@media (max-width: 991px) {
  .imgact2 {
    max-width: 600px;
    width: auto;
  }
}

.box_blank {
  height: 130px;
  position: relative;
}

.box_long {
  height: 580px;
  width: 400px;
  position: relative;
  box-shadow: 0 12px 15px rgba(0, 0, 0, 0.1), 0 17px 50px rgba(0, 0, 0, 0.1);
}

.box_auto {
  padding: 10px;
  height: auto;
  margin-bottom: 10px;
  position: relative;
  box-shadow: 0 12px 15px rgba(0, 0, 0, 0.1), 0 17px 50px rgba(0, 0, 0, 0.1);
}

.bordered_content {
  padding: 20px;
  border: 3px solid #1a2c77;
  margin: 10px;
  font-size: 12px;
}

.bordered_name_box {
  padding: 20px;
  border: 3px solid #1a2c77;
  background: #dddada;
  width: fit-content;
  max-height: 20px;
  position: relative;
  text-align: center;
  margin-bottom: -25px;
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.padding_minus {
  margin-top: -45px;
  font-size: 1.5rem;
}

.no-wrap-container {
  white-space: nowrap;
}

.customfont1 h4 {
  font-weight: 900;
  color: black;
  font-size: 18px;
}

.customfont1 {
  text-align: center;
}

.avatar {
  height: 55px;
  width: 55ipx;
  border-radius: 50%;
}

#circle {
  width: 55px;
  height: 55px;
  background-color: #9a9a9a;
  border-radius: 50%;
  margin-top: -30px;
}

.customfont1 ul,
ol,
dl {
  padding-left: 1rem !important;
  font-size: 17px !important;
}

/* .bordered_content .row p {
  font-size: 1.3rem;
} */

.blog_card_content strong {
  font-size: 24px;
}

.customfont1 p {
  font-size: 16px;
}

.box_wide div p,
.box_wide p {
  font-size: 15px;
}

.for-mobile {
  padding-left: 4rem;
}

.post-activity ul {
  margin-left: 4rem;
}



@media (max-width: 991px) {
  .for-mobile {
    padding-left: unset !important;
  }

  .post-activity ul {
    margin-left: unset !important;
  }

  h2.title__line1 {
    font-size: 21px;
  }

  .streamLines {
    margin-left: 211px;
  }

}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

/* .streamLines {
  font-size: 15px !important;
  margin-left: 36px;
} */

.in-school {
  width: 123rem;
}

.box-white p {
  font-size: 16px;
}

.icon-text ul {
  margin-left: 3rem;
}

.icon-text ul li {
  font-size: 16px;
}

.header-section p {
  font-size: 16px;
}


.left .form-group input {
  font-size: 14px;
  height: 33px;
}



/* .box_wide .ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
} */

@media (min-width: 992px) {
  .navbar-collapse.collapse {
    margin-left: 13rem;
  }
}


@media screen and (max-width: 1200px) and (min-width: 992px) {
  .solutions {
    height: 500px;
  }

  .ourSolutions {
    margin-bottom: 506px;
  }

  .streamLines {
    font-size: 15px !important;
    margin-left: 28px;
  }

  .Functions h1 {
    font-size: 36px;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .Functions h1 {
    font-size: 20px;
  }

}

@media screen and (max-width: 576px) {
  .streamLines {
    margin-left: 147px;
  }
}



@media (min-width: 768px) {

  .container-fluid>.navbar-collapse,
  .container-fluid>.navbar-header,
  .container>.navbar-collapse,
  .container>.navbar-header {
    margin-right: 0;
    margin-left: 0;
  }
}

@media screen and (min-width: 1200px) {
  .ourSolutions {
    margin-bottom: 347px;
  }
}

.contact-us {
  margin-bottom: 161px;
}


/* @media screen and (max-width: 991px) and (min-width: 626px) {
  .customfont1 h4 {
    font-size: 14px !important;
  }

  .customfont1 ul {
    font-size: 10px !important;
  }
} */

@media only screen and (max-width: 390px) {
  .logo-img {
    width: 100px;
  }

  button.navbar-toggler.collapsed {
    font-size: 8px;
  }

  .navbar-default .navbar-brand {
    margin-left: 0px;
  }

  .navbar {
    flex-wrap: wrap-reverse;
    padding: 0px 10px 0px 10px;
  }

  nav.bg-body-tertiary.navbar-default.navbar.navbar-expand-lg.navbar-light {
    margin-top: -20px;
  }
}

@media only screen and (max-width: 190px) {
  .navbar {
    flex-wrap: wrap;
  }

  a.navbar-brand.navbar-brand {
    margin: 0px;
  }

  nav.bg-body-tertiary.navbar-default.navbar.navbar-expand-lg.navbar-light {
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 990px) {
  .contact-us {
    margin-bottom: 0;
  }

  .container {
    margin-top: 0px;
  }

  .edvantum-section {
    padding: 0px;
  }
}

.card-columns {
  column-count: 4;
  grid-column-gap: 2.25rem;
  column-gap: 2.25rem;
  orphans: 1;
  widows: 1;
}

.event-card .card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 18px;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4);
  }

  a {
    color: initial;

    &:hover {
      text-decoration: initial;
    }
  }

  .text-muted i {
    margin: 0 10px;
  }

  img {
    width: 100%;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
  }

  .card-title {
    margin-bottom: 0px;
  }

  .card-body {
    padding: 2rem;
    border-radius: 18px;
  }
}

.event-card .card {
  margin-bottom: 30px;
}